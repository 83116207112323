<!--
 * @Date: 2023-09-26 11:03:00
 * @LastEditTime: 2024-03-24 13:23:05
 * @Description: file content
-->
<template>
  <div class="about-us">
    <Viewheader :isHome="false" theme="dark" />
    <div class="about-us-content">
      <!-- <div class="about-us-title">{{ title }}</div> -->
      <div class="about-us-header">
        <div class="about-us-header_cont">
          <div class="about-us-title">{{ title }}</div>
          <div class="about-us-text">
            <div v-for="(item, index) in contents" class="about-us-item">
              {{ item }}
            </div>
          </div>
        </div>
      </div>
      <SectionArticle
        class="team-info"
        :title="team.title"
        :desc="team.contents"
      >
      </SectionArticle>
      <SectionArticle class="contact-us" title="联系我们">
        <template v-slot:contents>
          <ul>
            <li v-for="(item, index) in cards" :key="index">
              <div>
                <!-- <p class="card-company">{{ item.company }}</p> -->
                <h5 class="card-title">{{ item.title }}</h5>
                <p class="card-list" v-for="(i, idx) in item.list" :key="idx">
                  <!-- <img src="../../assets/img/aboutUs/right.png" alt="" /> -->
                  {{ i }}
                </p>
              </div>
              <div>
                <img :src="item.qrCode" class="qrcode" />
                <p class="card-desc">{{ item.desc }}</p>
              </div>
            </li>
          </ul>
        </template>
      </SectionArticle>
    </div>
    <Footer />
  </div>
</template>

<script>
import Viewheader from '../../components/viewheader/viewheader.vue';
import SectionArticle from './components/SectionArticle.vue';
import Footer from '../../components/foot/footer.vue';
import '../../assets/css/public.css';
import '../../assets/css/normalize.css';
export default {
  name: 'abooutus',
  components: {
    Viewheader,
    Footer,
    SectionArticle,
  },
  data() {
    return {
      title: '关于我们',
      contents: [
        'AIShipGo是一个专注于跨境电商人工智能技术与解决方案的产品技术平台。凭借着海量的电商数据、国际领\n先的算法和工程能力，以“让跨境电商的生意更简单、更高效”为目标，致力于为跨境卖家提供全面的人工\n智能技术支持，解决社媒引流、站内营销、风控安全、直播带货等业务问题。',
      ],
      team: {
        title: '团队介绍',
        contents:
          '团队由一群经验丰富的AI专家和跨境电商从业者组成，深入了解跨境电商行业的挑战和机遇，结合先进的NLP、CV、多模态、ML等AIGC技术，为卖家提供全方位的解决方案。产品均已在真实跨境业务中应用，后续将持续开放更多能力。提供网页、SDK、私有化部署等对接方式，满足不同的应用需求，请关注我们，了解更多的最新动态。',
      },
      cards: [
        {
          company: '@数贸科技',
          title: 'Aishipgo咨询',
          qrCode: require('../../assets/img/erweima2.png'),
          desc: '如您有任何疑问\n可微信扫码咨询',
          list: ['产品使用及场景应用；', '效果及问题反馈。'],
        },
        {
          company: '@数贸科技',
          title: 'aishipgo 应用对接-小墩',
          qrCode: require('../../assets/img/erweima1.png'),
          desc: '如您需接口批量\n调用请微信咨询',
          list: ['接口化批量处理及业务场景规模应用。'],
        },
      ],
    };
  },
};
</script>

<style scoped>
.about-us {
  width: 100%;
  /* font-family: PingFangSC-Semibold; */
}
.about-us-content {
  box-sizing: border-box;
  margin: -80px auto 0;
  padding-bottom: 120px;
}
.about-us-title {
  color: #000108;
  font-size: 48px;
  text-align: center;
  margin-bottom: 16px;
  /* margin: 20px 0 30px; */
}
.about-us-header {
  position: relative;
  padding-bottom: 36.1%;
  background-image: url(../../assets/img/aboutUs/bg.png);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: bottom center;
}
.about-us-header_cont {
  position: absolute;
  top: 50%;
  transform: translateY(-33%);
  width: 100%;
}
.about-us-text {
  color: #5d5f6c;
  font-size: 18px;
  line-height: 26px;
  font-weight: 500;
  white-space: pre-line;
}
.about-us-text .about-us-item {
  /* margin-bottom: 20px; */
  text-align: center;
  font-family: PingFang-SC-Medium, PingFang-SC;
}
.about-us-join-img {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.about-us-join-img img {
  width: 200px;
  margin-right: 40px;
}
.team-info {
  width: 1280px;
  margin: auto;
  padding: 80px 0;
}
.contact-us {
  width: 1280px;
  margin: auto;
}
.contact-us ul {
  display: flex;
  justify-content: space-between;
  column-gap: 24px;
  margin-top: 16px;
}
.contact-us li {
  display: flex;
  justify-content: space-between;
  width: 50%;
  padding: 88px 71px 72px 36px;
  box-sizing: border-box;
  border-radius: 16px;
  color: #fff;
  white-space: pre-line;
}
.contact-us li:first-child {
  background: #6c38e0;
}
.contact-us li:last-child {
  background: #ff8645;
}
.qrcode {
  width: 105px;
  height: 105px;
  border: 3.5px solid #fff;
  border-radius: 8px;
}
.card-company {
  font-size: 14px;
  width: fit-content;
  padding: 6px 5px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  margin-bottom: 8px;
}
.card-title {
  font-size: 24px;
  margin-bottom: 12px;
}
.card-list {
  font-size: 16px;
  /* margin-bottom: 12px; */
  display: flex;
  align-items: center;
  line-height: normal;
}
.card-list img {
  width: 20px;
  margin-right: 8px;
}
.card-desc {
  margin-top: 8px;
  color: rgba(255, 255, 255, 0.75);
  font-size: 14px;
  text-align: center;
}
@media screen and (min-width: 1440px) {
  .about-us-header {
    padding-bottom: 520px;
  }
}

@media screen and (max-width: 1280px) and (min-width: 990px) {
  .about-us-title {
    font-size: 36px;
    margin-bottom: 12px;
  }
  .about-us-text {
    font-size: 14px;
    line-height: 22px;
  }
  .card-company {
    font-size: 12px;
    padding: 6px 5px;
    border-radius: 4px;
    margin-bottom: 6px;
  }
  .card-title {
    font-size: 20px;
    margin-bottom: 20px;
  }
  .card-list {
    font-size: 14px;
    margin-bottom: 10px;
  }
  .team-info {
    width: 95%;
    padding: 60px 0;
  }
  .contact-us {
    width: 95%;
  }
  .contact-us li {
    padding: 36px 55px 58px 24px;
    border-radius: 16px;
  }
}

@media screen and (max-width: 990px) {
  .about-us-title {
    font-size: 24px;
    margin-bottom: 8px;
  }
  .about-us-text {
    font-size: 10px;
    line-height: 18px;
  }
  .card-company {
    font-size: 8px;
    padding: 3px 2px;
    border-radius: 4px;
    margin-bottom: 4px;
  }
  .card-title {
    font-size: 16px;
    margin-bottom: 16px;
  }
  .card-list {
    font-size: 10px;
    margin-bottom: 17px;
  }
  .team-info {
    width: 95%;
    padding: 40px 0;
  }
  .team-info {
    padding: 60px 0;
  }
  .contact-us {
    width: 95%;
  }
  .contact-us ul {
    flex-wrap: wrap;
  }
  .contact-us li {
    width: 100%;
    margin-bottom: 20px;
    padding: 36px 55px 50px 28px;
  }
}
</style>
